<template>
    <div class="DistributionIndex">
        <div class="tabs">
            <a-tabs v-model="tabs" :tabBarGutter="10">
                <a-tab-pane :key="item" :tab="item" v-for="(item,index) in tabsList"></a-tab-pane>
            </a-tabs>
        </div>
        <div class="container">
            <a-row type="flex">
                <a-col :span="18" class="flex" style="justify-content: end">
                    <div class="cut-show">
                        <a-icon type="appstore" :class="cutIcon == 1 ? 'ant-menu-item-selected' :''" @click="cutIcon = 1"/>
                        <div class="icon-line"></div>
                        <a-icon type="unordered-list" :class="cutIcon == 2 ? 'ant-menu-item-selected' :''" @click="cutIcon = 2"/>
                    </div>
                    <!--<div class="col-input">-->
                        <!--<a-select v-model="selectType" style="width: 130px;">-->
                            <!--<a-select-option value="">请选择类型</a-select-option>-->
                        <!--</a-select>-->
                    <!--</div>-->
                    <div class="col-input">
                        <a-range-picker v-model="selectDate" style="width: 220px;" @change="_createdDate"/>
                    </div>
                    <div class="col-input">
                        <a-input  v-model="searchInput" placeholder="搜索活动名称"></a-input>
                    </div>
                    <div class="col-input">
                        <a-button style="margin-right: 15px" @click="_reset">重置</a-button> <a-button type="primary" @click="_init(1)">查询</a-button>
                    </div>
                </a-col>
                <a-col :span="6" style="text-align: right">
                    <a-button type="primary" style="width: 80px;position: relative" @click="_showAddAlert">
                        <span>创建</span>
                        <!--<a-select class="add-select" v-model="showType">-->
                            <!--<a-select-option :value="item.text" v-for="(item,index) in addList">{{item.text}}</a-select-option>-->
                        <!--</a-select>-->
                    </a-button>
                </a-col>
            </a-row>
            <!--列表显示-->
            <a-row type="flex" style="margin-top: 15px;" v-if="cutIcon==1">
                <a-col :span="3" class="item" v-for="(item,index) in tableData">
                    <div @mouseover="_cutShowItem(index,item)" @mouseleave="showItem = -1">
                        <div class="theme-img">
                            <img :src="item.ImgUrls" alt="">
                            <div class="shade" v-show="showItem == index"></div>
                            <div class="code-bg" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 150px;height: 170px;background: #fff" v-show="showItem == index">
                                <div class="view-qrcode" :id="'view-qrcode'+item.Id"></div>
                                <div style="position: absolute;bottom: 5px;left: 50%;transform: translateX(-50%);width: 100%;color: #666666;text-align: center;font-size: 15px;">微信扫码体验活动</div>
                            </div>
                        </div>
                        <div class="item-title">{{item.Name}}</div>
                        <div style="min-height: 50px;">
                            <div class="flex" style="margin: 5px 0;" v-show="showItem != index">
                                <span class="num"></span>
                                <span class="status" :class="item.Status=='进行中'?'active':''"> <i></i> {{item.Status}}</span>
                            </div>
                            <div class="flex" style="margin: 5px 0;" v-show="showItem == index">
                                <div class="tabs-btn-list" @mouseout="_hoverActive(-1)">
                                    <div @mouseover="_hoverActive(1)" @click="_clickActive(1,item,index)">
                                        <div class="tabs-btn-icon"><i class="iconfont icon-bianji" :class="hoverActive==1?'ant-menu-item-selected':''"></i></div>
                                        <div class="tabs-btn-text" :class="hoverActive==1?'ant-menu-item-selected':''">编辑</div>
                                    </div>

                                    <div @mouseover="_hoverActive(2)" @click="_clickActive(2,item)">
                                        <div class="tabs-btn-icon"><i class="iconfont icon-tubiao_fabu" :class="hoverActive==2?'ant-menu-item-selected':''"></i></div>
                                        <div class="tabs-btn-text" :class="hoverActive==2?'ant-menu-item-selected':''">链接</div>
                                    </div>

                                    <!--<div @mouseover="_hoverActive(3)" @click="_clickActive(3,item)">-->
                                    <!--<div class="tabs-btn-icon"><i class="iconfont icon-tubiao_shuju" :class="hoverActive==3?'ant-menu-item-selected':''"></i></div>-->
                                    <!--<div class="tabs-btn-text" :class="hoverActive==3?'ant-menu-item-selected':''">数据</div>-->
                                    <!--</div>-->

                                    <div @mouseover="_hoverActive(4)" @click="_clickActive(4,item)" v-if="item.TypeName=='试卷' && item.isAuth==1">
                                        <div class="tabs-btn-icon"><i class="iconfont icon-tubiao_pigai" :class="hoverActive==4?'ant-menu-item-selected':''"></i></div>
                                        <div class="tabs-btn-text" :class="hoverActive==4?'ant-menu-item-selected':''">批改</div>
                                    </div>
                                </div>
                                <a-dropdown :placement="'topLeft'" :getPopupContainer="triggerNode => triggerNode.parentNode">
                                    <i class="iconfont icon-shenglve" style="font-size: 20px"></i>
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a href="javascript:;" @click="_issueProject(item,index)">{{item.Status=='未开始'?'开始发布':'停止发布'}}</a>
                                        </a-menu-item>
                                        <!--<a-menu-item>-->
                                        <!--<a href="javascript:;" @click="_preview(item)">预览</a>-->
                                        <!--</a-menu-item>-->
                                        <a-menu-item>
                                            <a href="javascript:;" @click="_copy(item,index)">复制</a>
                                        </a-menu-item>
                                        <a-menu-item>
                                            <a href="javascript:;" @click="_del(item,index)">删除</a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <!--表格显示-->
            <a-row class="table" style="margin-top: 15px;" v-if="cutIcon==2">
                <a-table :columns="tableColumns" :data-source="tableData">
                    <div slot='themeImage' slot-scope="text,record" class="theme-img">
                        <img :src="text" alt="">
                    </div>
                    <div slot="status" slot-scope="text,record" :class="text=='进行中'?'table-status':''">{{text}}</div>
                    <div slot="operation" slot-scope="text,record,index" class="operation">
                        <span class="ant-menu-item-selected" @click="_clickActive(1,record,index)">编辑</span>
                        <span class="ant-menu-item-selected" @click="_clickActive(2,record)">链接</span>
                        <!--<span class="ant-menu-item-selected">数据</span>-->
                        <a-dropdown :placement="'topLeft'" :getPopupContainer="triggerNode => triggerNode.parentNode">
                            <span class="ant-menu-item-selected">更多</span>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a href="javascript:;" @click="_issueProject(record,index)">{{record.Enable=='未发布'?'开始发布':'停止发布'}}</a>
                                </a-menu-item>
                                <!--<a-menu-item>-->
                                    <!--<a href="javascript:;" @click="_preview(record)">预览</a>-->
                                <!--</a-menu-item>-->
                                <a-menu-item>
                                    <a href="javascript:;" @click="_copy(record,index)">复制</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;" @click="_del(item,index)">删除</a>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </a-table>
            </a-row>
            <a-pagination v-if="pageCount > 10" :total="pageCount" @change="_cutPage"/>
        </div>

        <!--创建弹框-->
        <a-drawer title="创建" placement="right" width="700" :closable="false" :visible="addVisible" @close="_addVisibleClose">
            <div style="padding-bottom: 50px;">
                <a-row type="flex" class="alert-row">
                    <div class="row-label">
                        <i>*</i> <span>标题</span>
                    </div>
                    <div class="row-input">
                        <a-input v-model="addTitle" placeholder="请输入"></a-input>
                    </div>
                </a-row>

                <a-row type="flex" class="alert-row">
                    <div class="row-label">
                        <i>*</i> <span>类型</span>
                    </div>
                    <div class="row-input">
                        <a-select v-model="selectType" style="width: 300px;">
                            <a-select-option :value="item" v-for="(item,index) in tabsList">{{item}}</a-select-option>
                        </a-select>
                    </div>
                </a-row>

                <a-row type="flex" class="alert-row">
                    <div class="row-label">
                        <i>*</i> <span>时间</span>
                    </div>
                    <div class="row-input">
                        <a-range-picker v-model="addDate" style="width: 300px;"/>
                    </div>
                </a-row>

                <a-row type="flex" class="alert-row">
                    <div class="row-label">
                        <i>*</i> <span>图片</span>
                    </div>
                    <div class="row-input">
                        <div style="position: relative">
                            <div v-if="uploadImg" style="position: relative;width:100px;height:100px;display: inline-block;vertical-align: top;margin-right: 15px;">
                                <img :src="uploadImg[0].FileUrl" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);max-width:100%;max-height: 100%;" alt="">
                            </div>
                            <div style="display: inline-block;vertical-align: top;position: relative">
                                <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" list-type="picture-card" style="position: relative">
                                    <div>
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">上传图片</div>
                                    </div>
                                </a-upload>
                                <input type="file" id="upload-img" @change="_uploadAttachment($event)" placeholder="" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 1000;opacity: 0">
                            </div>
                        </div>
                        <div class="suggest">建议尺寸：750 * 400</div>
                    </div>
                </a-row>

                <a-row type="flex" class="alert-row">
                    <div class="row-label">
                        <i>*</i> <span>链接</span>
                    </div>
                    <div class="row-input">
                        <a-input v-model="addLink" placeholder="请输入"></a-input>
                    </div>
                </a-row>

                <a-row type="flex" class="alert-row">
                    <div class="row-label">
                        <i>*</i> <span>活动内容</span>
                    </div>
                    <div class="row-input">
                        <div style="height:200px;width:480px;display: inline-block;">
                            <div id="websiteEditorElem" style="width:100%;height:100%"></div>
                        </div>
                    </div>
                </a-row>
            </div>

            <div class="alert-footer">
                <a-button style="margin-right:25px" @click.stop="_addVisibleClose">取消</a-button>
                <a-button type="primary" @click="_save">确定</a-button>
            </div>
        </a-drawer>
        <!--分享链接-->
        <a-modal v-model:visible="linkVisible" title="分享" @ok="_lineHandleOk" @cancel="_linkHandleCanle">
            <div class="share-alert">
                <div class="alert-title">你可以将"<span class="ant-menu-item-selected">{{selectItemData.Name}}</span>"通过以下方式分享出去</div>
                <div>活动二维码</div>
                <div class="qr-code">
                    <div class="qrcode-content" ref="qrCodeUrl"></div>
                    <!--<img src="https://smart-resource.sikegroup.com/FrDLgVaXCGmWhjQuyEkyZReF9o_8" alt="">-->
                </div>
                <div class="ant-menu-item-selected" style="text-align: center;border-bottom: 1px solid #E0E3E9;padding-bottom: 15px">
                    <div @click="_skipQrcode" style="cursor: pointer">下载二维码</div>
                </div>
                <div style="padding: 15px 0;">活动链接</div>
                <div class="skip-url flex">
                    <a-input :value="selectItemData.LinkUrl+'&Id='+selectItemData.Id" ref="copy" /> <a-button type="primary" class="copy-btn" ghost @click="_copyUrl">复制链接</a-button>
                </div>
            </div>
        </a-modal>
        <!--数据弹框-->
        <a-drawer title="名单" placement="right" width="1000" :closable="false" :visible="listVisible" @close="_listVisibleClose">
            <div class="flex" style="margin-bottom: 20px;">
                <div>
                    <a-select style="width: 110px;margin-right: 15px;">
                        <a-select-option value="">请选择</a-select-option>
                    </a-select>

                    <a-select style="width: 110px;margin-right: 15px;">
                        <a-select-option value="">请选择</a-select-option>
                    </a-select>

                    <a-range-picker style="width: 210px;margin-right: 15px;"/>

                    <a-input placeholder="客户姓名" style="width: 110px;margin-right: 15px;"></a-input>

                    <a-button style="margin-right: 15px">重置</a-button> <a-button type="primary">查询</a-button>
                </div>
                <a-button type="primary" class="btn" @click="showModal">名单分配</a-button>
            </div>

            <a-table bordered :columns="ScheduleTable" :dataSource="detailListData" :pagination="false" :rowSelection="{selectedRowKeys: NameListKeys, onChange: NameListChange}">
                <div slot="studentname" slot-scope="text, record" @click="_skipSkim(record.Id,record.StudentName)">
                    <span style="color: #2994ff">{{record.StudentName}}</span>
                </div>
                <div slot="operation" slot-scope="text, record">
                    <a-icon type="phone" style="cursor: pointer;font-size: 18px;margin-right: 10px;" @click="_relationVisible(record)" />
                    <a-icon type="file-text" style="cursor: pointer;font-size: 18px;" @click="_tailVisible(record)" />
                </div>
            </a-table>

            <div class="alert-footer">
                <a-button style="margin-right:25px" @click.stop="_listVisibleClose">取消</a-button>
                <a-button type="primary">确定</a-button>
            </div>
        </a-drawer>
        <!--名单分配-->
        <a-modal v-model:visible="visible" title="名单分配" @ok="handleOk" @cancel="handleCanle">
            <div class="flex" style="margin-bottom: 15px">
                <span class="row-label" style="display: inline-block;width: 120px;">选择中心</span>
                <div style="flex: 1">
                    <a-select style="margin-left:20px;width:350px" v-model="nameSelectedCenter" placeholder="请选择" @change="_SelectedCenter">
                        <a-select-option disabled selected hidden value="">请选择</a-select-option>
                        <a-select-option :value="item.Id" v-for="(item, index) in nameArrCenter">{{item.OrgName}}</a-select-option>
                    </a-select>
                </div>
            </div>

            <div class="flex" style="margin-bottom: 15px">
                <span class="row-label" style="display: inline-block;width: 120px;">选择顾问</span>
                <div style="flex: 1">
                    <a-select style="margin-left:20px;width:350px" v-model="selectConsultant" placeholder="请选择">
                        <a-select-option disabled selected hidden value="">请选择</a-select-option>
                        <a-select-option :value="item.Id" v-for="(item, index) in consultantArr">{{item.Name}}</a-select-option>
                    </a-select>
                </div>
            </div>

            <div class="flex">
                <span class="row-label" style="display: inline-block;width: 120px;">备注</span>
                <div style="flex: 1">
                    <a-input style="margin-left:48px;width:350px" v-model="nameListNote" placeholder="请输入" />
                </div>
            </div>
        </a-modal>

        <a-modal v-model="relationVisible" title="联系方式" :footer="null">
            <p>登陆手机：{{relationData.Phone}}</p>
            <p>父亲姓名：{{relationData.Fname}}</p>
            <p>父亲电话：{{relationData.Ftel}}</p>
            <p>母亲姓名：{{relationData.Mname}}</p>
            <p>母亲电话：{{relationData.Mtel}}</p>
            <p>家庭地址：{{relationData.HomeAddr}}</p>
        </a-modal>

        <a-drawer title="跟踪记录" placement="right" width="500" :closable="true" :visible="tailVisible" @close="tailVisible = false">
            <div v-for="(item,index) in relationData.TrecordList" style="border-bottom: 1px solid #ddd;margin: 20px 0;padding: 10px;">
                <p style="font-size: 14px;">{{item.RecordTit}}</p>
                <p style="font-size: 12px;color: #aaa;margin: 10px 0;text-align: right">创建于 {{item.CreateTime}} by
                    {{item.PersonName}}</p>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import E from 'wangeditor';
    import QRCode from 'qrcodejs2';
    const userinfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
    export default {
        name: "DistributionIndex",
        components: {E,QRCode},
        data(){
            return {
                tabsList: ['全部','H5','页面','图文','活动','第三方'],
                addList: [
                    {
                        text: 'H5',
                        icon: '',
                    },
                    {
                        text: '页面',
                        icon: '',
                    },
                    {
                        text: '图文',
                        icon: '',
                    },
                    {
                        text: '活动',
                        icon: '',
                    },
                    {
                        text: '第三方',
                        icon: '',
                    },
                ],
                tabs: '全部',
                cutIcon: 1,
                hoverActive: -1,
                showItem: -1,
                tableColumns: [
                    {
                        title: '推广主图',
                        dataIndex: 'ImgUrls',
                        key: 'ImgUrls',
                        scopedSlots: { customRender: 'themeImage' },
                    },
                    {
                        title: '推广名称',
                        dataIndex: 'Name',
                        align: 'center',
                        key: 'Name',
                    },
                    {
                        title: '推广起止时间',
                        dataIndex: 'StartTime',
                        align: 'center',
                        key: 'StartTime',
                    },
                    {
                        title: '状态',
                        dataIndex: 'Status',
                        align: 'center',
                        key: 'Status',
                        scopedSlots: { customRender: 'status' },
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateUserName',
                        align: 'center',
                        key: 'CreateUserName',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'CreateTime',
                        align: 'center',
                        key: 'CreateTime',
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        key: 'operation',
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    },
                ],
                tableData: [
                    {
                        themeImage: 'https://smart-resource.sikegroup.com/FrDLgVaXCGmWhjQuyEkyZReF9o_8',
                        name: '12月份成功发布会',
                        date: '2020.12.12-2020.12.11',
                        status: '进行中',
                        enterprise: '七田真',
                        num: '1200',
                        created: 'LYZ',
                        createdTime: '2012.12.12',
                    }
                ],
                addVisible:  false,
                linkVisible: false,
                listVisible: false,
                visible: false,
                showType: '',
                phoneEditor: '',
                selectItemData: '',
                copyUrl: '',
                setIndex: -1,
                pageCount: 0,

                selectType: '全部',
                selectDate: [],
                searchInput: '',

                addTitle: '',
                addDate: '',
                addLink: '',
                addActivity: '',
                uploadImg: '',

                ScheduleTable: [
                    {
                        title: "序号",
                        dataIndex: "sNumber",
                        width: 50,
                        align: "center",
                    },
                    {
                        title: "客户姓名",
                        dataIndex: "StudentName",
                        width: 100,
                        align: "center",
                        scopedSlots: {
                            customRender: 'studentname'
                        },
                    },
                    {
                        title: "电话",
                        dataIndex: "UserName",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "所属中心",
                        dataIndex: "SchoolName",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "负责人",
                        dataIndex: "Person",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "订单数量/订单金额",
                        dataIndex: "OrderInfo",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "创建时间",
                        dataIndex: "CreateTime",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "二维码来源",
                        dataIndex: "QrcodeUrl",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "状态",
                        dataIndex: "Status",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: "名单状态",
                        dataIndex: "TrackStatus",
                        width: 100,
                        align: "center",
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'operation'
                        },
                    },
                ],
                detailListData: [
                    {
                        sNumber: '1',
                        StudentName: 'LYZ',
                        UserName: '18682245836',
                        SchoolName: '宝安中心',
                        Person: '李泳章',
                        OrderInfo: 1200,
                        CreateTime: '2020-12-12',
                        QrcodeUrl: '线上网络',
                        Status: '未跟踪',
                        TrackStatus: '未跟踪',
                    }
                ],
                nameArrCenter: '',
                consultantArr: '',
                nameSelectedCenter: "",
                selectConsultant: '',
                nameListNote: '',
                NameListKeys: [],
                NameList: '',
                relationData: '',
                tailVisible: false,
                relationVisible: false
            }
        },
        created(){
            this._init(1);
        },
        watch: {
            tabs: function(i){
                this._init(1);
            },
        },
        methods: {
            _save(status){
                this.$message.loading('提交中...',0);
                if(this.addActivity == ''){
                    this.$message.error('活动内容不能为空!!');
                    return false;
                }
                this.$axios.post(5836189,{
                    Id: this.setIndex != -1? this.tableData[this.setIndex].Id : '',
                    Name: this.addTitle,
                    StartTime: this.addDate?this.__moment__(this.addDate[0]).format('YYYY-MM-DD'):'',
                    EndTime: this.addDate?this.__moment__(this.addDate[1]).format('YYYY-MM-DD'):'',
                    ImgUrls: this.uploadImg?this.uploadImg[0].FileUrl:'',
                    LinkUrl: this.addLink,
                    Content: this.addActivity,
                    Status: status || status===0 ? status:1,
                    Type: this.selectType
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==0){
                        if(this.setIndex==-1){
                            this._init(1);
                        }else{
                            this.tableData[this.setIndex].Name = this.addTitle;
                            this.tableData[this.setIndex].StartTime = this.addDate?this.__moment__(this.addDate[0]).format('YYYY-M-D'):'';
                            this.tableData[this.setIndex].EndTime = this.addDate?this.__moment__(this.addDate[1]).format('YYYY-M-D'):'';
                            this.tableData[this.setIndex].ImgUrls = this.uploadImg?this.uploadImg[0].FileUrl:'';
                            this.tableData[this.setIndex].LinkUrl = this.addLink;
                            this.tableData[this.setIndex].Content = this.addActivity;
                            this.tableData[this.setIndex].Status  = status==1?'进行中':'未发布';
                            this.tableData[this.setIndex].Type = this.selectType
                        }
                        this.$message.success(res.data.msg);
                        this._addVisibleClose();
                        document.getElementById('upload-img').value = '';
                    }else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            _showAddAlert(){
                let self = this;
                this.addVisible = true;
                setTimeout(function () {
                    self.phoneEditor = new E('#websiteEditorElem');
                    self.phoneEditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor',  // 文字颜色
                        'backColor',  // 背景颜色
                        'list',
                        'table',
                        'link',
                        'justify',
                        'image',
                        'emoticon'
                    ];
                    self.phoneEditor.customConfig.emotions = [
                        {
                            title: '默认',  // tab 的标题
                            type: 'emoji', // 'emoji' / 'image'
                            // emoji 表情，content 是一个数组即可
                            content: self.emoji.split(/\s/),
                        }
                    ];
                    self.phoneEditor.customConfig.onchange = function (html) {
                        self.addActivity = html;
                    };
                    // 创建一个富文本编辑器
                    self.phoneEditor.create();
                    self.phoneEditor.txt.html(self.phoneEditorHtml);
                })
            },
            _init(page){
                this.$message.loading('加载中...',0);
                this.$axios.get(5836187,{
                    p: page,
                    pagesize: 20,
                    Name: this.searchInput,
                    StartTime: this.selectDate.length==0?'':this.__moment__(this.selectDate[0]).format('YYYY-MM-DD'),
                    EndTime: this.selectDate.length==0?'':this.__moment__(this.selectDate[1]).format('YYYY-MM-DD'),
                    Type: this.tabs=='全部'? '' : this.tabs
                },res=>{
                    this.$message.destroy();
                    this.tableData = res.data.data;
                    this.pageCount = res.data.count;
                    if(res.data.code!=0){
                        this.$message.error(res.data.msg)
                    }
                });
            },
            _cutPage(page){
                this._init(page)
            },
            _reset(){
                this.searchInput = '';
                this.selectDate = [];
                this.startDate = '';
                this.endDate = '';
            },
            _uploadAttachment(e){
                this.$message.loading('上传中...');
                this.__uploadFile__(e.target.files,'/qiniu/homework_token').then(files=>{
                    this.uploadImg = files;
                    this.$message.destroy();
                });
            },
            _createdDate(value){
                this.startEndDate = value;
                this.startDate = value[0]._d;
                this.endDate = value[1]._d;
                let date1 = new Date(this.startDate);
                this.startDate = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1)+'-'+(date1.getDate()<10?'0'+date1.getDate():date1.getDate());
                let date2 = new Date(this.endDate);
                this.endDate = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1)+'-'+(date2.getDate()<10?'0'+date2.getDate():date2.getDate());
            },
            _tailVisible(data) {
                this.relationData = data;
                this.tailVisible = true;
            },
            _relationVisible(data) {
                this.relationData = data;
                this.relationVisible = true;
            },
            _skipSkim(id,name){
                let data = {
                    type: 'student',
                    name:name,
                    id:id
                };
                window.parent.postMessage(data,'*');
            },
            NameListChange(selectedRowKeys, selectedRows) {
                this.NameListKeys = selectedRowKeys;
                this.NameList = selectedRows;
            },
            _SelectedCenter() {
                this.$axios.post(8000025, {
                    UserId: this.SaasUserId,
                    SchoolId: this.nameSelectedCenter
                }, res => {
                    if (res.data.code == 1) {
                        this.consultantArr = res.data.data;
                    }
                });
            },
            handleCanle() {
                this.nameSelectedCenter = "";
                this.selectConsultant = "";
                this.nameListNote = "";
            },
            handleOk() {
                let length = this.NameList.length;
                if (length != 0) {
                    this.userlist = this.NameList[0].Id;
                    for (let i = 1; i < length; i++) {
                        let data = this.NameList[i].Id;
                        this.userlist += "," + data;
                    }
                }
                this.$axios.post(8000026, {
                    schoolid_new: this.nameSelectedCenter,
                    newperson: this.selectConsultant,
                    remark: this.nameListNote,
                    userlist: this.userlist,
                }, res => {
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.NameListKeys = [];
                    }else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            showModal() {
                if (this.detailListData.length == 0) {
                    this.$message.error("亲，你没有可以选择的学生，不能使用该功能");
                } else if (this.NameListKeys.length == 0) {
                    this.$message.warning("亲，你还没有选择学生，请先选择左边的学生");
                } else {
                    this.visible = true;
                    this.$axios.get(8000024, {
                        UserId: userinfo.uid
                    }, res => {
                        if (res.data.code == 1) {
                            this.nameArrCenter = res.data.data;
                        }
                    });
                }

            },
            _listVisibleClose(){
                this.listVisible = false;
            },
            _copyUrl(url){
                this.$refs.copy.select();
                document.execCommand("Copy");
                this.$message.success('复制成功')
            },
            _linkHandleCanle(){
                this.linkVisible = false;
            },
            _lineHandleOk(){

            },
            _addVisibleClose(){
                this.addVisible = false;
                this.addTitle = '';
                this.addDate = '';
                this.addLink = '';
                this.addActivity = '';
                this.uploadImg = '';
                this.setIndex = -1;
                document.getElementById('websiteEditorElem').innerHTML = '';
            },
            _cutShowItem(i,data){
                let self = this;
                if(this.showItem != i){
                    this.showItem = i;
                    setTimeout(function () {
                        let dom = document.getElementById('view-qrcode'+data.Id);
                        dom.innerHTML = '';
                        self._addQrCode(data.LinkUrl+'&Id='+data.Id,dom,150 * 0.8)
                    });
                }
            },
            _addQrCode(url,dom,w){
                let self = this;
                let qrcode = new QRCode(dom, {
                    text: url,
                    width: w ? w : 60,
                    height: w ? w : 60,
                    colorDark: '#666666',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });

            },
            _setData(value,index){
                let self = this;
                this.setIndex = index;
                this.addTitle = value.Name;
                this.addDate = [this.__moment__(value.StartTime),this.__moment__(value.EndTime)];
                this.addLink = value.LinkUrl;
                this.addActivity = value.Content;
                this.uploadImg = [{FileUrl:value.ImgUrls}];
                this.selectType = value.Type;
                setTimeout(function () {
                    self.phoneEditor = new E('#websiteEditorElem');
                    self.phoneEditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor',  // 文字颜色
                        'backColor',  // 背景颜色
                        'list',
                        'table',
                        'link',
                        'justify',
                        'image',
                        'emoticon'
                    ];
                    self.phoneEditor.customConfig.emotions = [
                        {
                            title: '默认',  // tab 的标题
                            type: 'emoji', // 'emoji' / 'image'
                            // emoji 表情，content 是一个数组即可
                            content: self.emoji.split(/\s/),
                        }
                    ];
                    self.phoneEditor.customConfig.onchange = function (html) {
                        self.addActivity = html;
                    };
                    // 创建一个富文本编辑器
                    self.phoneEditor.create();
                    self.phoneEditor.txt.html(self.addActivity);
                })
            },
            _clickActive(i,value,index){
                let self = this;
                this.selectItemData = value;
                if(i==3){
                    this.listVisible = true;
                    //this.$router.push({path:'/statisticsQuestionnaire',query:{id:value.Id,type:value.TypeName=='试卷'?2:1}})
                }else if(i==4){
                    this.$router.push({path:'/addCorrect',query:{id:value.Id}})
                }else if(i==1){
                    this.addVisible = true;
                    this._setData(value,index);

                }else if(i==2){
                    this.linkVisible = true;
                    setTimeout(function () {
                        self.$refs.qrCodeUrl.innerHTML = '';
                        let qrcode = new QRCode(self.$refs.qrCodeUrl, {
                            text: self.selectItemData.LinkUrl+'&Id='+self.selectItemData.Id,
                            width: 180,
                            height: 180,
                            colorDark: '#000000',
                            colorLight: '#ffffff',
                            correctLevel: QRCode.CorrectLevel.H
                        });
                    },100)
                    //this.$router.push({path:'/issueQuestionnaire',query:{id:value.Id,type:value.TypeName=='试卷'?2:1}})
                }
            },
            _skipQrcode(){
                this.copyUrl = document.getElementsByClassName('qrcode-content')[0].childNodes[1].getAttribute('src');
                const imgUrl = this.copyUrl;
                // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
                if (window.navigator.msSaveOrOpenBlob) {
                    var bstr = atob(imgUrl.split(',')[1]);
                    var n = bstr.length;
                    var u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    var blob = new Blob([u8arr]);
                    window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png');
                } else {
                    // 这里就按照chrome等新版浏览器来处理
                    const a = document.createElement('a');
                    a.href = imgUrl;
                    a.setAttribute('download', 'chart-download');
                    a.click();
                }
            },
            _hoverActive(i){
                this.hoverActive = i;
            },
            _issueProject(data,index){
                let status = this.tableData[index].Status == '进行中' ? 0 : 1;
                this.setIndex = index;
                this._setData(data,index);
                this._save(status);
            },
            _preview(data){

            },
            _copy(data,index){
                this.addVisible = true;
                this._setData(data,-1);
            },
            _del(data,index){
                this.$axios.post(5836188, {
                    Id: data.Id,
                }, res => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.tableData.splice(index,1);
                    }else{
                        this.$message.error(res.data.msg)
                    }
                });
            }
        },
    }
</script>

<style scoped lang="less">
    .DistributionIndex{
        width: 100%;
        height: 100%;
        color: #666666;
        .container{
            min-height: calc(~'100% - 50px');
            padding: 20px 15px;
            background: #fff;
            border-radius: 8px;
            .cut-show{
                display: inline-block;
                padding: 5px 8px;
                border: 1px solid #EAEAEA;
                .icon-line{
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 8px 0;
                    width: 2px;
                    height: 12px;
                    background: #aaa;
                }
                i{
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 17px;
                    cursor: pointer;
                }
            }
            .col-input{
                margin: 0 10px;
            }
            .add-select{
                position: absolute;
                top: 0;
                left: -40px;
                width: 120px;
                height: 100%;
                opacity: 0;
                text-align: center;
            }
            .item{
                box-sizing: content-box;
                margin: 0 10px 10px 0;
                padding: 8px;
                border: 1px solid #F3F3F9;
                .theme-img{
                    position: relative;
                    height: 200px;
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        max-width: 100%;
                        max-height: 100%;
                    }
                    .shade{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #000;
                        opacity: .5;
                    }
                    .view-qrcode{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: 80%;
                        height: 80%;
                    }
                }
                .item-title{
                    margin: 5px 0 20px;
                    font-size: 16px;
                    color: #242424;
                }
                .num{
                    font-size: 14px;
                    color: #999999;
                }
                .tabs-btn-list{
                    color: #999;
                    >div{
                        display: inline-block;
                        text-align: center;
                        margin-right: 15px;
                        cursor: pointer;
                        .tabs-btn-text{
                            font-size: 12px;
                        }
                        .tabs-btn-icon{
                            font-size: 17px;
                            i{
                                font-size: 20px;
                            }
                        }
                    }
                }
                .status{
                    color: #666;
                    font-size: 14px;
                    i{
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #666;
                    }
                    &.active{
                        color: #75C255;
                        i{
                            background: #75C255;
                        }
                    }
                }
            }
            .table{
                .theme-img{
                    position: relative;
                    width: 100px;
                    height: 60px;
                    img{
                        position: absolute;
                        top: 50%;
                        left:50%;
                        transform: translate(-50%,-50%);
                        max-width: 95%;
                        max-height: 95%;
                    }
                }
                .table-status{
                    display: inline-block;
                    padding: 2px 8px;
                    color: #52C41A;
                    border: 1px solid #52C41A;
                    background: #F6FFED;
                    font-size: 12px;
                }
                .operation{
                    white-space: nowrap;
                    span{
                        cursor: pointer;
                        display: inline-block;
                        vertical-align: middle;
                        margin: 10px;
                    }
                }
            }
        }
    }
    .alert-row{
        margin-bottom: 20px;
        .row-label{
            width: 80px;
            i{
                display: inline-block;
                vertical-align: middle;
                color: #FF4D4F;
                font-size: 14px;
            }
            span{
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                color: #333333;
                font-size: 14px;
            }
        }
        .row-input{
            flex: 1;
            input{
                width: 300px;
            }
            .suggest{
                color: #ABB0BF;
                font-size: 10px;
            }
        }
    }
    .alert-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        z-index: 99999;
        button{
            width: 120px;
        }
    }
    .share-alert{
        .alert-title{
            margin-bottom: 25px;
        }
        .qr-code{
            position: relative;
            width: 200px;
            height: 200px;
            margin: 0 auto;
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: 98%;
                max-height: 98%;
            }
        }
        .skip-url{
            background: #fff;
            border-radius: 4px;
            input{
                height: 36px;
                font-size: 16px;
            }
            .copy-btn{
                height: 36px;
                margin-left: 25px;
            }
        }
    }
    .qrcode-content{
        width: 180px;
        height: 180px;
        margin: 0 auto;
        padding: 3px;
    }
</style>
